<template>
    <Section
        v-if="$validateText(slice.primary.header) || $validateText(slice.primary.text)"
        width="md"
        :label="slice.slice_label"
    >
        <div class="container" :class="background">
            <div 
                v-if="$validateText(slice.primary.header)"
                class="container-header rich-text"
                v-html="$prismic.asHtml(slice.primary.header)"   
            />
            <div
                v-if="$validateText(slice.primary.text)"
                class="container-content rich-text"
                v-html="$prismic.asHtml(slice.primary.text)"
            />
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        background() {
            return {
                'light-pink': 'bg-lpink',
                'light-gray': 'bg-lgray',
                'white': 'bg-white'
            }[this.slice.primary.background] || 'bg-lblue';
        }
    }
};
</script>

<style lang="scss" scoped>
    section {
        .container.bg-lpink {
            background: $lightpink;
        }
        .container.bg-lgray {
            background: $lightgray;
        }
        .container.bg-lblue {
            background: $lightblue;
        }
        .container.bg-white {
            background: $white;
        }
        .container {
            box-shadow: $bxs;
            @include border-radius(5px);
            @include spacing(padding, 5);
            width: 100%;

            &-header {
                color: $font;
                @include spacing(margin,6,bottom);
            }
        }
    }
    ::v-deep{
        ol {
            list-style: auto;
        }
        li {
            list-style: auto;
            @include spacing(padding, 0, left);
        }
    }
</style>
