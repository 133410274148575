<template>
    <li>
        <div class="credit-card">
            <div class="credit-card-header-wrapper">
                <div v-if="$validateText(highlightedText)" class="highlighted-text">
                    {{ highlightedText }}
                </div>
                <div class="credit-card-inner credit-card-header">
                    <div class="inner-container">
                        <nuxt-link :to="$prismic.linkResolver(card)">
                            <PrismicImage
                                :img="card.data.card_image"
                                w="250"
                                h="159"
                            />
                        </nuxt-link>

                        <ButtonTarget
                            v-if="card.data.redirect_enabled"
                            :link="card"
                            :placement="placement"
                            :position="position"
                            :text="$translate('cta_credit_card_list', 'Till ansökan')"
                            background="green"
                            size="md"
                            icon="fas fa-lock white"
                        />

                        <span
                            v-if="card.data.redirect_enabled"
                            class="text-subtile"
                        >
                            {{ $getApplySecurelyString(card) }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="credit-card-inner credit-card-body">
                <div class="container header">
                    <div class="header-inner">
                        <h3>{{ card.data.title }}</h3>

                        <CardStarsWithCount
                            v-if="model._ratings.count"
                            :data="model._ratings"
                            :show-count="false"
                        />
                    </div>

                    <div
                        class="header-inner"
                        @click="toggleCompare(id);"
                    >
                        <span class="text-highlight">{{ $translate('add_card_to_comparision') || 'Jämför kort' }}</span>
                        <div class="checkbox">
                            <i
                                v-if="compare"
                                class="fas fa-check"
                            />
                        </div>
                    </div>
                </div>

                <div class="container card-info">
                    <ListCardInfo 
                        :data="card" 
                        :model="model" 
                        :template="listTemplate"
                    />
                </div>

                <ul class="container options">
                    <li
                        v-for="(option, index) in infoOptions"
                        :key="`option${index}`"
                        :class="{lined: active === option.value}"
                        class="text-highlight"
                        @click="active = option.value"
                    >
                        {{ option.text }}
                    </li>
                </ul>

                <ul
                    v-if="active === 'pros'"
                    class="container list"
                >
                    <li
                        v-for="(pro, index) in pros"
                        :key="`pro${index}`"
                        class="list-icon list-check"
                        v-html="$removeParagraphs($prismic.asHtml(pro))"
                    />
                </ul>

                <div
                    v-if="active === 'details'"
                >
                    <template v-if="$isGermany()">
                        <ul class="container list details">
                            <li>
                                <span>{{ $translate('yearly_fee_year_1') }}</span>
                                <span>{{ $format.currency(model.firstYearCost) || model.yearlyCostString }}</span>
                            </li>
                            <li>
                                <span>{{ $translate('yearly_fee_from_year_2') }}</span>
                                <span>{{ model.yearlyCostString }}</span>
                            </li>
                            <li v-if="model.maxCredit > 0">
                                <span>{{ $translate('max_credit', 'Maxkredit') }}</span>
                                <span>{{ $format.currency(model.maxCredit) }}</span>
                            </li>
                            <li v-if="model.isCredit">
                                <span>{{ $translate('rate', 'Ränta') }}</span>
                                <span>{{ model.interestString }}</span>
                            </li>
                            <li v-if="model.isCredit">
                                <span>{{ $translate('effective_rate', 'Effektiv ränta') }}</span>
                                <span>{{ model.effectiveInterestString }}</span>
                            </li>
                        </ul>
                        <span class="header">{{ $translate('card_payment_costs') }}</span>
                        <ul class="container list details">
                            <li>
                                <span>Inland</span>
                                <span>{{ $format.percentage(0, 0) }}</span>
                            </li>
                            <li>
                                <span>Eurozone</span>
                                <span>{{ $format.percentage(0, 0) }}</span>
                            </li>
                            <li>
                                <span>Fremdwährung weltweit</span>
                                <span>{{ model.getCurrencyExchangeFeeString() }}</span>
                            </li>
                        </ul>
                        <span class="header">{{ $translate('card_withdrawal_fees') }}</span>
                        <ul class="container list details">
                            <li>
                                <span>Inland</span>
                                <span>{{ model.getWithdrawalFeeString() }}</span>
                            </li>
                            <li>
                                <span>Eurozone</span>
                                <span>{{ model.getWithdrawalFeeString() }}</span>
                            </li>
                            <li>
                                <span>Fremdwährung weltweit</span>
                                <span>
                                    {{ 
                                        (model.withdrawalFeePercentage === 0 && model.withdrawalFeeFixed === 0) ?
                                            model.getCurrencyExchangeFeeString() : 
                                            model.getWithdrawalFeeString() 
                                    }}
                                </span>
                            </li>
                        </ul>
                    </template>
                    <template v-else>
                        <ul class="container list details">
                            <li>
                                <span>{{ $translate('yearly_fee', 'Årsavgift') }}</span>
                                <span v-html="model.yearlyCostHtml" />
                            </li>
                            <li>
                                <span>{{ $translate('rate', 'Ränta') }}</span>
                                <span>{{ model.interestString }}</span>
                            </li>
                            <li>
                                <span>{{ $translate('effective_rate', 'Effektiv ränta') }}</span>
                                <span>{{ model.effectiveInterestString }}</span>
                            </li>
                            <li>
                                <span>{{ $translate('rate_free_days', 'Räntefria dagar') }}</span>
                                <span>{{ model.getInterestFreeDaysString($translate('core_models_rate_free_days', '{days} dagar.')) }}</span>
                            </li>
                            <li>
                                <span>{{ $translate('max_credit', 'Maxkredit') }}</span>
                                <span>{{ model.getMaxCreditString($translate('no_max_credit', 'Ingen kreditgräns')) }}</span>
                            </li>
                            <li>
                                <span>{{ $translate('currency_exchange_fee', 'Valutapåslag') }}</span>
                                <span>{{ model.getCurrencyExchangeFeeString() }}</span>
                            </li>
                            <li>
                                <span>{{ $translate('withdrawal_fee', 'Uttagsavgift') }}</span>
                                <span>{{ model.getWithdrawalFeeString() }}</span>
                            </li>
                            <li v-if="$isSweden() || $isNorway()">
                                <span>{{ $translate('invoice_fee', 'Aviavgift') }}</span>
                                <span>{{ model.getAviFeeString($translate('core_models_avi_fee', '{paperInvoiceFee} ({eInvoiceFee} e-faktura)')) }}</span>
                            </li>
                        </ul>
                    </template>
                    <!-- TODO: remove finland here when we have translations -->
                    <template v-if="!$isFinland()">
                        <span class="header">{{ $translate('mobile_payments') }}</span>
                        <ul class="container list details">
                            <li>
                                <span>Apple Pay</span>
                                <i class="fas" :class="model.hasApplePay ? 'fa-check-circle' : 'fa-times-circle'" />
                            </li>
                            <li>
                                <span>Google Pay</span>
                                <i class="fas" :class="model.hasGooglePay ? 'fa-check-circle' : 'fa-times-circle'" />
                            </li>
                            <li>
                                <span>Samsung Pay</span>
                                <i class="fas" :class="model.hasSamsungPay ? 'fa-check-circle' : 'fa-times-circle'" />
                            </li>
                        </ul>
                    </template>
                </div>



                <ul
                    v-if="active === 'requirements'"
                    class="container list"
                >
                    <li
                        v-for="(requirement, index) in $getRequirements(model)"
                        :key="`demand${index}`"
                        class="list-icon list-demand"
                    >
                        {{ requirement }}
                    </li>
                </ul>

                <div
                    v-if="active === 'review'"
                    class="container review"
                >
                    <p>{{ $prismic.asText(card.data.good_for) }}</p>

                    <ButtonNuxt
                        :link="card"
                        background="pink"
                        size="sm"
                        :text="$translate('card_list_to_card_page', 'Läs hela recensionen')"
                    />
                </div>
            </div>

            <div
                v-if="card.data.redirect_enabled"
                class="credit-card-inner credit-card-footer"
            >
                <ButtonTarget
                    :link="card"
                    :placement="placement"
                    :position="position"
                    :text="$translate('cta_credit_card_list', 'Till ansökan')"
                    background="green"
                    size="md"
                    class="flex"
                    icon="fas fa-lock white"
                />
                <span class="text-subtile">{{ $getApplySecurelyString(card) }}</span>
            </div>
        </div>

        <span
            v-if="$isNorway()"
            class="compliance text-subtile"
        >
            {{ model.complianceExample }}
        </span>
    </li>
</template>
<script>
import { includes } from 'lodash';
import CardStarsWithCount from '@/components/stars/StarsWithCount.vue';
import ListCardInfo from '@/components/slices/lists/ListCardInfo.vue';
export default {
    components: { CardStarsWithCount, ListCardInfo },
    props: {
        id: {
            type: String,
            required: true,
            default: String
        },
        placement: {
            type: String,
            required: false,
            default: null
        },
        position: {
            type: Number,
            required: false,
            default: null
        },
        slice: {
            type: Object,
            required: false,
            default: null
        },
        highlightedText: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            active: 'pros',
        };
    },
    computed: {
        listTemplate() {
            if (!this.slice) {
                return 'default';
            }

            return this.slice.primary.list_template || 'default';
        },
        isBonusList() {
            return this.listTemplate === 'bonus';
        },
        isTravelList() {
            return this.listTemplate === 'travel';
        },
        isFuelList() {
            return this.listTemplate === 'fuel';
        },
        isShoppingList() {
            return this.listTemplate === 'shopping';
        },
        card() {
            return this.$store.state.cards[this.id];
        },
        model() {
            return new this.$models.CreditCard(this.card);
        },
        compare() {
            return includes(this.$store.state.cardsToCompare, this.id);
        },
        pros() {
            if (this.isBonusList) {
                const bonusPros = (this.card.data.bonus_list_pros || [])
                    .map(iterator => iterator.pro)
                    .filter(pro => pro && this.$prismic.asText(pro)?.trim().length);

                if (bonusPros.length > 0) {
                    return bonusPros;
                }
            }
            else if (this.isTravelList) {
                const travelPros = (this.card.data.travel_list_pros || [])
                    .map(iterator => iterator.pro)
                    .filter(pro => pro && this.$prismic.asText(pro)?.trim().length);

                if (travelPros.length > 0) {
                    return travelPros;
                }
            }
            else if (this.isFuelList) {
                const fuelPros = (this.card.data.fuel_list_pros || [])
                    .map(iterator => iterator.pro)
                    .filter(pro => pro && this.$prismic.asText(pro)?.trim().length);

                if (fuelPros.length > 0) {
                    return fuelPros;
                }
            }
            else if (this.isShoppingList) {
                const shoppingPros = (this.card.data.shopping_list_pros || [])
                    .map(iterator => iterator.pro)
                    .filter(pro => pro && this.$prismic.asText(pro)?.trim().length);

                if (shoppingPros.length > 0) {
                    return shoppingPros;
                }
            }

            return this.card.data.pros
                .map(iterator => iterator.pro)
                .filter(pro => pro && this.$prismic.asText(pro)?.trim().length);
        },
        prosHeader() {
            if (this.slice && this.slice.primary.pros_header) {
                return this.slice.primary.pros_header;
            }
            else if (this.isTravelList) {
                return this.$translate('card_list_travel_pros_header', 'Resefördelar');
            }
            else if (this.isBonusList) {
                return this.$translate('card_list_bonus_pros_header', 'Bonus & fördelar');
            }
            else if (this.isFuelList) {
                return this.$translate('card_list_fuel_pros_header', 'Bensinrabatter');
            }
            else if (this.isShoppingList) {
                return this.$translate('card_list_shopping_pros_header', 'Shoppingfördelar');
            }

            return this.$translate('card_list_pros_header', 'Fördelar');
        },
        infoOptions() {
            return [
                {
                    value: 'pros',
                    text: this.prosHeader
                },
                {
                    value: 'details',
                    text: this.$translate('card_list_details_header', 'Detaljer')
                },
                {
                    value: 'requirements',
                    text: this.$translate('card_list_requirements_header', 'Krav')
                },
                {
                    value: 'review',
                    text: this.$translate('card_list_short_review_header', 'Recension')
                }
            ];
        }
    },
    methods: {
        toggleCompare: function(cardId) {
            if (this.compare) this.$store.commit('REMOVE_CARD_FROM_COMPARE', cardId);
            else this.$store.commit('ADD_CARD_TO_COMPARE', cardId);
        }
    }
};
</script>
<style lang="scss" scoped>
.credit-card.show {
    animation: show .3s ease-in forwards;
}
@keyframes show {
    from {
        opacity: 0;
        transform: translateX(50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
    .credit-card {
        @include border-radius(5px);
        box-shadow: $bxs;
        @include flex(center, start);
        @include flex(center, stretch);
        overflow: hidden;
        .credit-card-header-wrapper {
            width: 100%;
            flex: 1;
            @include device(pad) {
                max-width: 330px;
                width: auto;
            }
            display: flex;
            flex-direction: column;
            align-items: center;
            .highlighted-text {
                font-family: $fontText;
                width: 100%;
                @include spacing(padding, 2);
                background: $pink;
                color: $white;
                text-align: center;
                font-size: 20px;
            }
        }

        &-inner {
            width: 100%;
            @include flex(center,start);
            @include spacing(padding, 5);

            @include device(pad) {
                width: auto;
            }

            .inner-container {
                @include flex;
                @include grid(1, 10px);

            }

            .container { width: 100%; }

            .lined { border-bottom: 3px solid $pink; }
        }

        &-inner.credit-card-header {
            text-align: center;
            max-width: 320px;

            @include device(pad) {
                flex: 1;
                max-width: 330px;
            }

            img { height: auto; }
        }

        &-inner.credit-card-body {
            @include grid(1, 25px);
            background: $lightblue;
            @include device(pad) { flex: 2; }

            .container.header {
                @include grid(1, 20px);

                @include device(pad) {
                    @include flex(between,start);
                }

                .header-inner {
                    text-align: center;

                    @include device(pad) {
                        text-align: left;
                    }

                    &:first-child {
                        @include grid(1, 10px);
                    }

                    &:last-child {
                        cursor: pointer;
                        @include flex;

                        @include device(pad) {
                            @include flex(between);
                        }
                    }

                    span {
                        display: inline;
                        @include spacing(margin,3,right);
                    }

                    .checkbox {
                        @include flex;
                        @include size(20px);
                        border: 2px solid $pink;
                        border-radius: 3px;

                        i {
                            @include font-size(20px, 0px);
                            color: $green;
                        }
                    }
                }
            }

            .container.options {
                @include flex(start);
                gap: 1rem;
                @include device(pad) {
                    gap: 1.25rem;
                }
                li {
                    @include font-size(16px);
                    cursor: pointer;

                    @include device(pad) {
                        @include font-size(18px);
                    }
                }
            }

            .container.list {
                @include grid(1, 10px);

                li {
                    font-family: $fontText;
                    @include font-size(14px);
                }
            }

            .list.details {
                gap: 0;

                li {
                    @include flex(between);
                    @include spacing(padding, 2);

                    span {
                        &:last-child {
                            font-weight: bold;
                            font-family: $fontHeader;
                            text-align: right;

                            @media screen and (max-width: 400px) {
                                width: 100%;
                                text-align: left;
                                padding-top: 10px;
                            }

                            @include device(pad) {
                                flex: 1;
                                width: auto;
                            }
                        }
                    }

                    i.fa-check-circle {
                        color: $green;
                    }
                    i.fa-times-circle {
                        color: $red;
                    }
                }

                li:nth-child(odd) { background: $white; }
            }

            span.header {
                font-family: $fontText;
                @include spacing(padding, 2);
                @include font-size(14px);
                display: block;
                margin-top: 15px;
                font-weight: bold;
            }

            .container.review {
                @include flex(start,start);
                width: 100%;

                p {
                    @include font-size(14px);
                }

                .btn { @include spacing(margin, 6, top);}
            }
        }

        &-inner.credit-card-footer {
            @include grid(1, 10px);
            text-align: center;

            @include device(pad) {
                display: none;
            }
        }
    }


    .compliance {
        @include spacing(margin, 2, top);
        display: block;
    }
</style>
