<template>
    <Section
        v-if="enabled()"
        width="md"
    >
        <div class="container">
            <div class="container-inner">
                <PrismicImage
                    :img="author.data.image"
                    h="70"
                    w="70"
                />

                <div class="author-publication-header">
                    <nuxt-link :to="$prismic.linkResolver(author)" class="author-name">
                        {{ author.data.name }}
                    </nuxt-link>
                    <a 
                        v-if="author.data.email" 
                        :href="`mailto:${author.data.email}`"
                        class="author-publication-email"
                    >
                        <i class="fas fa-envelope" />
                        <span>{{ author.data.email }}</span>
                    </a>
                </div>
            </div>


            <p 
                class="rich-text" 
                v-html="$removeParagraphs($prismic.asHtml(author.data.bio))"
            />

            <span class="author-publication-date">
                {{ publicationDate }}
            </span>

            <div v-if="editor.data" class="author-publication-container">
                <div class="author-publication-body">
                    <PrismicImage
                        class="author-publication-image"
                        :img="editor.data.image"
                        h="30"
                        w="30"
                    />
                    <nuxt-link 
                        :to="$prismic.linkResolver(editor)" 
                        class="author-publication-name"
                    >
                        <p>
                            {{ $translate('authority_box_reviewed_by', 'granskad av') }}
                            <span>{{ editor.data.name }}</span>
                        </p>
                    </nuxt-link>
                </div>
            </div>
        </div>

        <div
            v-if="sources.length || alternatePages.length"
            class="container"
        >
            <div
                v-if="sources.length"
                class="container-inner"
            >
                <span>{{ $translate('authority_box_sources', 'Källor') }}</span>

                <ul class="container-inner__list">
                    <li
                        v-for="(source, index) in sources"
                        :key="`authorityboxsources${index}`"
                    >
                        <a
                            :href="source.link_url"
                            target="_blank"
                        >
                            {{ $truncateString(source.link_url, 60) }}
                        </a>
                    </li>
                </ul>
            </div>

            <!-- NOTE: Can't show the other languages if it's an english page
                    as the hreflangs has been "tampered" with in get-document.js.
                    This might also make sense as it's a special situation where we 
                    might not need to show other languages
                TODO: can we make this less hacky?  -->
            <div
                v-if="(!document.data.is_english_page) && alternatePages.length"
                class="container-inner"
                :class="{last: sources.length}"
            >
                <span>{{ $translate('authority_box_other_languages', 'På andra språk') }}</span>

                <ul class="container-inner__list">
                    <li
                        v-for="(alternate, index) in alternatePages"
                        :key="`authorityboxeshreflangs${index}`"
                    >
                        <a
                            :href="alternate.href"
                            target="_blank"
                        >
                            <img :src="getAlternateIcon(alternate.hreflang)" loading="lazy">
                            {{ formatUrl(alternate.href) }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </Section>
</template>

<script>
import locales from '@/config/locales.config.js';
import { capitalize } from 'lodash';
export default {
    props: {
        document: {
            type: Object,
            required: true,
            default: () => {}
        },
        hreflangs: {
            type: Array,
            required: true,
            default: () => []
        }
    },
    data() {
        return {
            locales: locales,
            publishedDate: this.$format.date(this.document.first_publication_date, 'DATE_MED'),
            lastUpdatedDate: this.$format.date(this.document.last_publication_date, 'DATE_MED')
        };
    },
    computed: {
        publicationDate() {
            if (this.publishedDate === this.lastUpdatedDate) {
                return this.$translate('authority_box_first_publication_date', 'Publicerad {date}')
                    .replace('{date}', this.publishedDate);
            }
            return this.$translate('authority_box_last_publication_date', 'Senast uppdaterad {date}')
                .replace('{date}', this.lastUpdatedDate);
        },
        author() {
            return this.document.data.author;
        },
        editor() {
            return this.document.data.editor;
        },
        locale() {
            return this.locales.find(locale => locale.prismic === process.env.PRISMIC_LANG);
        },
        sources() {
            return this.document.data.sources.filter(this.$filterEmptyElements);
        },
        alternatePages() {
            return this.hreflangs.filter(object => object.hreflang !== this.locale.hreflang);
        }
    },
    methods: {
        formatUrl(url) {
            return capitalize(url.split('https://')[1]);
        },
        enabled() {
            return this.document.data.author.data;
        },
        getAlternateIcon(hreflang) {
            return this.locales.find(locale => locale.hreflang === hreflang).icon;
        }
    }
};
</script>

<style lang="scss" scoped>
    section::v-deep {

        .section-inner {
            box-shadow: $bxs;
            @include flex(center,stretch);
            @include border-radius(3px);

            .container {
                @include spacing(padding,5);
                width: 100%;

                @include device(pad) {
                    width: auto;
                    flex: 1;
                }

                &:first-child {
                    .container-inner {
                        @include flex;
                        padding-bottom: 20px;
                        margin-bottom: 20px;
                        @include border(bottom);
                        gap: 1rem;
                        justify-content: flex-start;
                        img { @include border-radius(100px); }
                        .author-publication-header {
                            display: flex;
                            flex-flow: wrap column;
                            gap: 0.5rem;
                            .author-name {
                                flex: 1;
                                color: $font;
                                font-family: $fontHeader;
                                @include font-size(24px);
                                &:hover {
                                    color: $pink;
                                    text-decoration: underline;
                                }
                            }
                            .author-publication-email {
                                span {
                                    font-family: $fontHeader;
                                    @include font-size(16px);
                                    color: #677788;
                                    
                                    &:hover { color: $pink; }
                                }


                                i {
                                    color: $pink;
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                    .author-publication-date {
                        font-family: $fontText;
                        @include font-size(14px);
                        opacity: .5;
                        font-weight: lighter;
                        margin-top: 20px;
                        display: inline-block;
                    }

                    

                    p { @include font-size(14px, 10px); }

                }

                &:last-child {
                    .container-inner {

                        span {
                            @include font-size(24px);
                            font-family: $fontHeader;
                            display: inline-block;
                            @include device(pad) {
                                margin-top: 5px;
                            }
                        }

                        &__list {
                            @include spacing(margin, 4, top);
                            @include grid(1, 5px);

                            li a {
                                font-family: $fontText;
                                @include font-size(14px);
                                color: $pink;
                                @include flex(start);
                                word-break: break-all;

                                &:hover { text-decoration: underline; }

                                img {
                                    @include size(30px, auto);
                                    margin-right: 10px;
                                }
                            }
                        }
                    }

                    .container-inner.last {
                        @include spacing(margin, 4, top);
                        @include spacing(padding, 4, top);
                        @include border(top);
                    }
                }

                .author-publication-container {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5rem;
                    margin-top: 20px;
                    border-top: .0625rem solid rgba(33, 50, 91, .1);
                    
                    .author-publication-body {
                        display: flex;
                        gap: 0.5rem;
                        align-items: center;
                        margin-top: 20px;

                        .author-publication-image {
                            border-radius: 50%;
                        }
                        
                        .author-publication-name {
                            color: $font;
                            p {
                                font-family: $fontText;
                                margin: 0;
                            }
                            p::first-letter {
                                text-transform: capitalize;
                            }
                            p span {
                                font-weight: bold;
                            }
                            p:hover {
                                span {
                                    color: $pink;
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
